import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'

import Seo from '../components/seo';
import Layout from '../components/layout';
import TextBanner from '../components/text-banner/text-banner';
import OverlayBlockTeam from '../components/overlay-block-team/overlay-block-team';
import StaffMembersBlock from '../components/staff-block/staff-block';

class InfrastructureAndSupply extends React.Component {
    render() {
        const infrastructureTextBanner = get(this, 'props.data.infrastructureTextBanner');
        const infrastructureOverlayBlockTeam = get(this, 'props.data.infrastructureOverlayBlockTeam');
        const infrastructureStaffMembersBlock = get(this, 'props.data.infrastructureStaffMembersBlock');
        return (
            <Layout location={this.props.location}>
                <Seo title="Meet The Team"/>
                    <div className="container-fluid container-fluid--page">
                        <div className="meet-team">
                            <TextBanner textOne={infrastructureTextBanner.textOne} textTwo={infrastructureTextBanner.textTwo} />
                            <OverlayBlockTeam image={infrastructureOverlayBlockTeam.backgroundImage.url} headingText={infrastructureOverlayBlockTeam.headingText}
                                descriptionText={infrastructureOverlayBlockTeam.descriptionText} boxOneNumber={infrastructureOverlayBlockTeam.boxOneNumber}
                                boxOneText={infrastructureOverlayBlockTeam.boxOneText} boxTwoNumber={infrastructureOverlayBlockTeam.boxTwoNumber}
                                boxTwoText={infrastructureOverlayBlockTeam.boxTwoText} />
                            <StaffMembersBlock members={infrastructureStaffMembersBlock.nodes} />
                        </div>
                    </div>
            </Layout>
        )
    }
}

export default InfrastructureAndSupply;

export const query = graphql`
query InfrastructureAndSupply {
    infrastructureTextBanner: contentfulTextBanner(textBannerName: {eq: "infrastructureTextBanner"}) {
        textBannerName
        textOne
        textTwo
    }
    infrastructureOverlayBlockTeam: contentfulOverlayBlockTeam(overlayBlockName: {eq: "infrastructureOverlayBlockTeam"}) {
        overlayBlockName
        headingText
        descriptionText
        boxOneNumber
        boxOneText
        boxTwoNumber
        boxTwoText
        backgroundImage {
            url
        }
    }
    infrastructureStaffMembersBlock: allContentfulStaffMembersBlock(
        filter: {staffMembersBlockName: {glob: "*infrastructureStaffMembersBlock*"}}
      ) {
        nodes {
          name
          description
          image {
            url
          }
        }
    }
}
`
